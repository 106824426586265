import React, { useEffect } from 'react';
import './ExploreSection.css';
import propiedad2Img from '../../../assets/images/nuevas/muro2.png';
import propiedad3Img from '../../../assets/images/nuevas/muro3.png';
import video1 from '../../../assets/videos/explore2.mp4';
import video2 from '../../../assets/videos/explore3.mp4';

const ExploreSection = () => {
  useEffect(() => {
    const elementsToAnimate = document.querySelectorAll('.content-item');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, {
      threshold: 0.2 
    });

    elementsToAnimate.forEach((element) => observer.observe(element));

    return () => {
      elementsToAnimate.forEach((element) => observer.unobserve(element));
    };
  }, []);

  return (
    <section id="explore" className="explore-properties-section">
      <div className="container">
        <h2>Descubre y Comparte Propiedades en Nuestro Muro</h2>
        <p className="title-paragraph">Explora una amplia gama de propiedades y publica la tuya para conectar con potenciales compradores o arrendatarios.</p>
        
        {/* Primer contenedor: Texto a la izquierda, video a la derecha */}
        <div className="content-item">
          <div className="text">
            <h3>Explora propiedades con diversos filtros</h3>
            <p className="content-item-paragraph">Descubre propiedades destacadas utilizando filtros personalizados para encontrar exactamente lo que buscas.</p>
          </div>
          <div className="image">
            <video className="explore-video" autoPlay muted loop>
              <source src={video1} type="video/mp4" />
              Tu navegador no soporta el video.
            </video>
          </div>
        </div>

        {/* Segundo contenedor: Imagen a la izquierda, texto a la derecha */}
        <div className="content-item alternate">
          <div className="text">
            <h3>Vista de mapa de las propiedades</h3>
            <p className="content-item-paragraph">Amplía tu búsqueda utilizando la vista de mapa para explorar propiedades en distintas ubicaciones geográficas.</p>
          </div>
          <div className="image">
            <img src={propiedad2Img} alt="Propiedad 1" />
          </div>
        </div>

        {/* Tercer contenedor: Texto a la izquierda, video a la derecha */}
        <div className="content-item">
          <div className="text">
            <h3>Ve el detalle de cada propiedad</h3>
            <p className="content-item-paragraph">Accede a descripciones detalladas, imágenes y características específicas de cada propiedad en nuestro muro.</p>
          </div>
          <div className="image">
            <video className="explore-video" autoPlay muted loop>
              <source src={video2} type="video/mp4" />
              Tu navegador no soporta el video.
            </video>
          </div>
        </div>

        {/* Cuarto contenedor: Imagen a la izquierda, texto a la derecha */}
        <div className="content-item alternate">
          <div className="text">
            <h3>Crea una publicación</h3>
            <p className="content-item-paragraph">Publica tu propiedad en pocos pasos y detalla todo el proceso para atraer compradores o arrendatarios interesados.</p>
          </div>
          <div className="image">
            <img src={propiedad3Img} alt="Propiedad 1" />
          </div>
        </div>

        <button className="explore-button" onClick={() => window.location.href = 'https://alienrealtyhub-e4bdecd9adcfd3fb.centralus-01.azurewebsites.net'}>
          Visita nuestro muro
        </button>
      </div>
    </section>
  );
};

export default ExploreSection;
